import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { actions as stepActions, stepState, StepState } from "./slices/steps";

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
  reducer: {
    [stepState.name]: stepState.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const actions = {
  [stepState.name]: stepActions,
};

export interface GlobalState {
  [stepState.name]: StepState;
}
