import styled, { css } from "styled-components";

import { Divider as ChakDivider } from "@chakra-ui/react";

export const Root = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  @media ${(p) => p.theme.breakpoints.main} {
    flex-direction: row;
  }
`;

export const RankDisplay = styled.div`
  display: flex;
  align-items: center;

  @media ${(p) => p.theme.breakpoints.main} {
    flex-grow: 1;
    justify-content: center;
  }
`;

export const RankWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  padding-left: 16px;
  align-items: center;
  padding-right: 16px;
  border-radius: 30px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const RankPos = styled.div<{ pos: number }>`
  font-weight: 900;
  font-size: 40px;
  display: flex;
  align-items: center;

  ${(p) => {
    switch (p.pos) {
      case 1:
        return css`
          color: #c9b037;
        `;
      case 2:
        return css`
          color: #b4b4b4;
        `;
      case 3:
        return css`
          color: #ad8a56;
        `;
      default:
        return css`
          color: black;
        `;
    }
  }}
`;

export const DisplayContainer = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
`;

export const RankName = styled.div`
  flex-grow: 1;
  display: flex;
  font-size: 22px;
  font-weight: 300;
  align-items: center;
`;

export const RankSteps = styled.div`
  flex-grow: 1;
  display: flex;
  font-size: 26px;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled(ChakDivider)`
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom-width: 2px !important;

  @media ${(p) => p.theme.breakpoints.main} {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
    border-bottom-width: 0 !important;
    border-left-width: 2px !important;
  }
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 16px;
`;
