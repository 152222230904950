import styled from "styled-components";

import { Button } from "@chakra-ui/react";

export const Root = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 16px;
  gap: 32px;
  flex-direction: column;
  padding-bottom: 52px;
`;

export const AddStepsButton = styled(Button)`
  right: 16px;
  bottom: 16px;
  width: 180px;
  position: fixed !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
