import React from "react";

import { Route, Routes, useNavigate } from "react-router-dom";

import { Home } from "../../pages/Home";
import { AddSteps } from "../../pages/AddSteps";

import * as S from "./SiteBase.styles";
import { LogInLock } from "../LogInLock";

export function SiteBase() {
  const navigate = useNavigate();
  return (
    <S.Root>
      <S.NavBar onClick={() => navigate("/")}>
        <S.TitleText>Step Challenge 2022</S.TitleText>
      </S.NavBar>
      <S.ContentWrapper>
        <S.Content>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/add" element={<AddSteps />} />
          </Routes>
        </S.Content>
      </S.ContentWrapper>
      <LogInLock />
    </S.Root>
  );
}
