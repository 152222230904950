import styled from "styled-components";

import { Button as ChakraButton, Input as ChakraInput } from "@chakra-ui/react";

export const Root = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #edf2f7aa;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  backdrop-filter: blur(2px);
`;

export const Button = styled(ChakraButton)`
  width: 250px;
`;

export const Input = styled(ChakraInput)`
  background-color: white !important;
  max-width: 250px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
