import Cookies from "js-cookie";

import { StepTotals, TotalStepRecords } from "../types";

function getApiUrl() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "http://localhost:8899";
  }
  return "https://steps.kiwipolarbear.com:8899";
}

export function authHeaders() {
  return { Authorization: `Bearer ${Cookies.get("access_token") ?? ""}` };
}

async function getStepRecords(): Promise<TotalStepRecords | null> {
  const result = await fetch(`${getApiUrl()}/steps`, {
    method: "GET",
    headers: {
      ...authHeaders(),
    },
  });

  if (result.status != 200) {
    console.error(await result.text());
    return null;
  }

  return await result.json();
}

async function getStepTotals(): Promise<StepTotals | null> {
  const result = await fetch(`${getApiUrl()}/totals`, {
    method: "GET",
    headers: {
      ...authHeaders(),
    },
  });

  if (result.status != 200) {
    console.error(await result.text());
    return null;
  }

  return await result.json();
}

async function saveStepRecords(
  stepContent: TotalStepRecords
): Promise<boolean> {
  const result = await fetch(`${getApiUrl()}/steps`, {
    method: "POST",
    headers: {
      ...authHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stepContent),
  });

  if (result.status != 204) {
    console.error(await result.text());
    return false;
  }

  return true;
}

async function login(password: string): Promise<string | null> {
  const result = await fetch(`${getApiUrl()}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password }),
  });

  if (result.status != 200) {
    console.error(await result.text());
    return null;
  }

  return (await result.json()).token;
}

export const requests = {
  getStepRecords,
  saveStepRecords,
  getStepTotals,
  login,
};
