import React from "react";

import { useNavigate } from "react-router-dom";

import { Ranking } from "../../components/Ranking";
import { Breakdown } from "../../components/Breakdown";

import * as S from "./Home.style";

export function Home() {
  const navigate = useNavigate();
  return (
    <S.Root>
      <Ranking />
      <Breakdown />
      <S.AddStepsButton onClick={() => navigate("/add")} colorScheme="purple">
        Add Steps
      </S.AddStepsButton>
    </S.Root>
  );
}
