import React from "react";
import "./index.css";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider } from "styled-components";

import { store } from "./state";
import { appTheme } from "./theme";
import { SiteBase } from "./components/SiteBase";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <ChakraProvider>
          <Provider store={store}>
            <SiteBase />
          </Provider>
        </ChakraProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
