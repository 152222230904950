import React from "react";

import {
  Select,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getDate, getDaysInMonth } from "date-fns";

import * as S from "./AddSteps.style";
import { actions, useAppDispatch } from "../../state";

const START_DATE = new Date(2022, 7, 1);

enum Names {
  ADAM = "Adam",
  CARMEL = "Carmel",
  GABBY = "Gabby",
}

export function AddSteps() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [steps, setSteps] = React.useState(0);

  const lastUsedName = localStorage.getItem("step-challenge/last-used-name");
  const [name, setName] = React.useState((lastUsedName as Names) ?? Names.ADAM);

  const daysInMonth = getDaysInMonth(START_DATE);
  const dayOfMonth = getDate(Date.now());
  const [day, setDay] = React.useState(Math.min(dayOfMonth, daysInMonth));

  const submitSteps = () => {
    console.log(steps);
    dispatch(actions.steps.updateStepRecords({ [day]: { [name]: steps } }));
    localStorage.setItem("step-challenge/last-used-name", name);
    navigate("/");
  };

  return (
    <S.Root>
      <S.Content>
        <S.TitleText>Day</S.TitleText>
        <Select
          value={day}
          onChange={(val) => setDay(parseInt(val.target.value))}
        >
          {Array.from(new Array(daysInMonth).keys()).map((val) => (
            <option key={val + 1} value={val + 1}>
              {val + 1}
            </option>
          ))}
        </Select>
        <S.TitleText>Name</S.TitleText>
        <Select
          value={name}
          onChange={(val) => setName(val.target.value as Names)}
        >
          <option value={Names.ADAM}>Adam</option>
          <option value={Names.CARMEL}>Carmel</option>
          <option value={Names.GABBY}>Gabby</option>
        </Select>
        <S.TitleText>Steps</S.TitleText>
        <NumberInput
          min={0}
          value={isNaN(steps) ? "" : steps}
          onChange={(_, steps) => setSteps(steps ?? 0)}
          onBlur={() => {
            if (isNaN(steps)) {
              setSteps(0);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submitSteps();
            }
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </S.Content>
      <S.ButtonContainer>
        <Button colorScheme="gray" onClick={() => navigate("/")}>
          Cancel
        </Button>
        <Button colorScheme="purple" onClick={submitSteps}>
          Submit
        </Button>
      </S.ButtonContainer>
    </S.Root>
  );
}
