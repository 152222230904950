export interface AppTheme {
  colours: {
    primary: string;
    grey: string;
  };
  breakpoints: {
    main: string;
  };
}

export const appTheme: AppTheme = {
  colours: {
    primary: "#805ad5",
    grey: "#edf2f7",
  },
  breakpoints: {
    main: "screen and (min-width: 1024px)",
  },
};
