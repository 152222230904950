import React from "react";

import { getDate, getDaysInMonth } from "date-fns";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { selectors } from "../../state/selectors";
import { actions, useAppDispatch } from "../../state";
import { StepRecord, TotalStepRecords } from "../../types";

import * as S from "./Breakdown.style";

const START_DATE = new Date(2022, 7, 1);

function sortSteps(a: [string, number], b: [string, number]) {
  if (a[1] == b[1]) {
    return 0;
  }
  return a[1] > b[1] ? -1 : 1;
}

interface IRankContent {
  day: number;
  record: StepRecord | null;
}

function RankContent(props: IRankContent) {
  const { day, record } = props;

  return (
    <S.RankPanel>
      <S.RankDate>{day}</S.RankDate>
      <S.RankContent>
        {record ? (
          Object.entries(record)
            .sort(sortSteps)
            .map(([name, steps]) => {
              return (
                <S.ContentRow key={name}>{`${name}: ${steps}`}</S.ContentRow>
              );
            })
        ) : (
          <S.ContentRow grey>No data recorded</S.ContentRow>
        )}
      </S.RankContent>
    </S.RankPanel>
  );
}

interface IWeekRankings {
  startDay: number;
  endDay: number;
  records: TotalStepRecords;
}

function WeekRankings(props: IWeekRankings) {
  const { startDay, endDay, records } = props;

  const days = Array.from(
    { length: endDay - startDay + 1 },
    (_, i) => startDay + i
  );

  const weekTotals = days.reduce((acc, curr) => {
    const dayRecord = records?.[`${curr}`] ?? {};
    return {
      ...acc,
      ...Object.assign(
        {},
        ...Object.keys(dayRecord).map((key) => ({
          [key]: (dayRecord[key] ?? 0) + (acc?.[key] ?? 0),
        }))
      ),
    };
  }, {} as Record<string, number>);
  const rankings = Object.entries(weekTotals).sort(sortSteps);

  return (
    <S.TabContainer>
      <S.SubTitle>Weekly Rankings</S.SubTitle>
      <S.WeeklyRank>
        {rankings.map(([name, steps]) => {
          return <S.ContentRow key={name}>{`${name}: ${steps}`}</S.ContentRow>;
        })}
      </S.WeeklyRank>
      <S.SubTitle>Daily Rankings</S.SubTitle>
      <S.RankList>
        {days.map((day) => (
          <RankContent
            key={day}
            day={day}
            record={records?.[`${day}`] ?? null}
          />
        ))}
      </S.RankList>
    </S.TabContainer>
  );
}

export function Breakdown() {
  const dispatch = useAppDispatch();
  const records = useSelector(selectors.selectRecords);
  const isAuthorized = useSelector(selectors.isAuthorized);

  const daysInMonth = getDaysInMonth(START_DATE);
  const dayOfMonth = getDate(Date.now());
  const day = Math.min(dayOfMonth, daysInMonth);
  const dayRanges = [
    [1, 7],
    [8, 14],
    [15, 21],
    [22, daysInMonth],
  ];

  let defaultTabIndex = 0;
  dayRanges.forEach((val, i) => {
    if (day >= val[0] && day <= val[1]) {
      defaultTabIndex = i;
    }
  });

  React.useEffect(() => {
    if (isAuthorized) {
      dispatch(actions.steps.loadStepRecords());
    }
  }, [isAuthorized]);

  return (
    <S.Root>
      <S.Title>Breakdown</S.Title>
      <Tabs isFitted defaultIndex={defaultTabIndex}>
        <TabList>
          {dayRanges.map((_, i) => (
            <Tab key={i}>Week {i + 1}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {dayRanges.map((val, i) => (
            <TabPanel key={i}>
              <WeekRankings
                startDay={val[0]}
                endDay={val[1]}
                records={records}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </S.Root>
  );
}
