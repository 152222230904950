import styled, { css } from "styled-components";

export const Root = styled.div``;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 16px;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
`;

export const RankList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const RankPanel = styled.div`
  display: flex;
  min-height: 60px;
  flex-direction: row;
`;

export const RankDate = styled.div`
  padding: 8px;
  width: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  background-color: ${(p) => p.theme.colours.primary};
`;

export const RankContent = styled.div`
  flex-grow: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 0 8px 8px 0;
  background-color: ${(p) => p.theme.colours.grey};
`;

export const ContentRow = styled.div<{ grey?: boolean }>`
  font-size: 18px;
  flex-grow: 1;
  display: flex;
  align-items: center;

  ${(p) => p.grey && css`color gray; font-size: 16px;`}
`;

export const WeeklyRank = styled.div`
  flex-grow: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colours.grey};
`;

export const TabContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
