import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { requests } from "../../network/api";
import { TotalStepRecords } from "../../types";

export interface StepState {
  totals: Record<string, number>;
  records: TotalStepRecords;
  authorized: boolean;
}

const initialState: StepState = {
  totals: {},
  records: {},
  authorized: true,
};

const loadStepRecords = createAsyncThunk("steps/saveStepRecords", async () => {
  return await requests.getStepRecords();
});

const loadStepTotals = createAsyncThunk("steps/saveStepTotals", async () => {
  return await requests.getStepTotals();
});

const updateStepRecords = createAsyncThunk(
  "steps/noop",
  async (records: TotalStepRecords, thunkAPI) => {
    await requests.saveStepRecords(records);
    thunkAPI.dispatch(loadStepTotals());
    thunkAPI.dispatch(loadStepRecords());
  }
);

export const stepState = createSlice({
  name: "steps",
  initialState,
  reducers: {
    setAuthorized(state, action: PayloadAction<boolean>) {
      state.authorized = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadStepRecords.fulfilled, (state, action) => {
      if (action.payload) {
        state.records = action.payload;
      } else {
        state.authorized = false;
      }
    });
    builder.addCase(loadStepTotals.fulfilled, (state, action) => {
      if (action.payload) {
        state.totals = action.payload;
      } else {
        state.authorized = false;
      }
    });
  },
});

export const actions = {
  ...stepState.actions,
  loadStepRecords,
  loadStepTotals,
  updateStepRecords,
};
