import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
`;

export const NavBar = styled.div`
  display: flex;
  min-height: 80px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 1920px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

export const TitleText = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${(p) => p.theme.colours.primary};
`;
