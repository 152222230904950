import { createSelector } from "@reduxjs/toolkit";

import { GlobalState } from ".";

const selectState = (state: GlobalState) => state;
const selectStepsState = createSelector(selectState, (state) => state.steps);

const isAuthorized = createSelector(
  selectStepsState,
  (state) => state.authorized
);

const selectTotals = createSelector(selectStepsState, (state) => state.totals);

const selectRecords = createSelector(
  selectStepsState,
  (state) => state.records
);

export const selectors = {
  selectTotals,
  selectRecords,
  isAuthorized,
};
