import styled from "styled-components";

export const Root = styled.div`
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  gap: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  @media ${(p) => p.theme.breakpoints.main} {
    flex-direction: row;

    & > button {
      flex-grow: 1;
    }
  }
`;

export const TitleText = styled.div`
  font-size: 16px;
`;
