import React from "react";
import Cookies from "js-cookie";

import { useSelector } from "react-redux";
import { requests } from "../../network/api";

import { selectors } from "../../state/selectors";

import * as S from "./LogInLock.style";
import { actions, useAppDispatch } from "../../state";

export function LogInLock() {
  const dispatch = useAppDispatch();
  const isAuthorized = useSelector(selectors.isAuthorized);
  const [password, setPassword] = React.useState("");

  const onLogin = async () => {
    const response = await requests.login(password);
    if (response) {
      Cookies.set("access_token", response, {
        sameSite: "none",
        secure: true,
        expires: 365,
      });
      dispatch(actions.steps.setAuthorized(true));
    }
  };

  if (isAuthorized) {
    return null;
  }

  return (
    <S.Root>
      <S.Title>Step Challenge Login</S.Title>
      <S.Input
        value={password}
        type="password"
        placeholder="Enter Password"
        variant="filled"
        onChange={(event: any) => setPassword(event.target.value)}
      />
      <S.Button onClick={onLogin} colorScheme="purple">
        Submit
      </S.Button>
    </S.Root>
  );
}
