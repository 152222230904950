import React from "react";
import { useSelector } from "react-redux";
import { actions, useAppDispatch } from "../../state";
import { selectors } from "../../state/selectors";

import * as S from "./Ranking.style";

interface IRankDisplay {
  position: number;
  name: string;
  steps: number;
}

function RankDisplay(props: IRankDisplay) {
  const { position, name, steps } = props;
  return (
    <S.RankDisplay>
      <S.RankWrapper>
        <S.RankPos pos={position}>{position}</S.RankPos>
      </S.RankWrapper>
      <S.DisplayContainer>
        <S.RankName>{name}</S.RankName>
        <S.RankSteps>{steps} steps</S.RankSteps>
      </S.DisplayContainer>
    </S.RankDisplay>
  );
}

function sortSteps(a: [string, number], b: [string, number]) {
  if (a[1] == b[1]) {
    return 0;
  }
  return a[1] > b[1] ? -1 : 1;
}

export function Ranking() {
  const dispatch = useAppDispatch();
  const totals = useSelector(selectors.selectTotals);
  const sorted = Object.entries(totals).sort(sortSteps);
  const isAuthorized = useSelector(selectors.isAuthorized);

  React.useEffect(() => {
    if (isAuthorized) {
      dispatch(actions.steps.loadStepTotals());
    }
  }, [isAuthorized]);

  return (
    <S.Root>
      <S.Title>Rankings</S.Title>
      <S.Wrapper>
        {sorted.map(([name, steps], i) => (
          <>
            <RankDisplay
              key={name}
              name={name}
              steps={steps}
              position={i + 1}
            />
            {i < sorted.length - 1 && <S.Divider orientation="vertical" />}
          </>
        ))}
      </S.Wrapper>
    </S.Root>
  );
}
